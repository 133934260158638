<template>
  <div>
    <v-data-table
      :headers="headers"
      :footer-props="footerProps"
      :items="surveys"
      :loading="isLoading"
      :server-items-length="surveysCount"
      :options.sync="options"
    >
      <template v-slot:item.title="{ item }">
        <v-btn color="primary" dark @click="loadSurveyData(item)">
          {{ item.title }}
        </v-btn>
      </template>
      <template v-slot:item.associated="{ item, index }">
        <v-switch
          label="Associated"
          @click.stop="toggleAssociated(index)"
          v-model="item.associated"
        >
        </v-switch>
      </template>
      <template v-slot:no-data>
        <no-data-alert />
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      max-width="1200px"
      :open-on-click="false"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="endSurveyData()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ survey.title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid ref="pagesContainer">
            <v-tabs
              background-color="primary"
              center-active
              dark
              show-arrows
              v-if="surveyPageCount > 0"
            >
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab v-for="(page, index) in survey.surveyData.pages" :key="index"
                >{{ page.title }}
              </v-tab>
              <v-tab-item
                eager
                v-for="(page, index) in survey.surveyData.pages"
                :key="index"
              >
                <v-container fluid>
                  <page
                    :key="page.id"
                    :ref="'page-' + page.id"
                    :id="page.id"
                    class="item"
                    :readOnly="true"
                  >
                  </page>
                </v-container>
              </v-tab-item>
            </v-tabs>
            <p v-else>No pages created yet for this survey</p>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="associationSnackbar"
      :multi-line="true"
      color="primary"
      timeout="2000"
      outlined
      absolute
      bottom
      centered
      transition="slide-x-reverse-transition"
    >
      The survey was successfully {{ associationStatus }}.
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="warning"
          v-bind="attrs"
          @click="associationSnackbar = false"
        >
          <v-icon>mdi-close</v-icon>
          <span class="d-none" aria-hidden="true">Close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import Page from "../surveys_new/Page.vue";
import _ from "lodash";

export default {
  name: "ScreenerSurveysAssociationTable",
  props: {
    // Allow for the user of this component to indicate loading since data used in this component
    // must be loaded externally (e.g. which surveys should be shown should be loaded externally
    // based on what is appropriate for the current scope). We want the external context to load data,
    //  determine when the necessary data is loaded, and give this component permission to show it
    isLoading: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    screenerId: {
      required: true,
      type: String,
    },
    screenerSurveys: {
      required: false,
      type: Array,
      default: () => [],
    },
    surveys: {
      required: true,
      type: Array,
      default: () => [],
    },
    surveysCount: {
      required: true,
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "title",
          sortable: true,
        },
        {
          text: "Associated",
          value: "associated",
          sortable: false,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
      },
      associationSnackbar: false,
      associationStatus: "associated",
      errorSnackbar: false,
      dialog: false,
      survey: [],
      surveyPageCount: 0,
    };
  },
  computed: {
    ...mapState(["footerProps"]),
    ...mapState("screenerSurveys", {
      newScreenerSurveys: "screenerSurveys",
    }),
    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      if (this.options.sortBy.length) {
        data.sortBy = [];
        for (let i = 0; i < this.options.sortBy.length; i++) {
          let sortField = this.options.sortBy[i];
          const desc = this.options.sortDesc[i] === true ? "-" : "";
          data.sortBy.push(desc + sortField);
        }
      }

      return data;
    },
  },
  watch: {
    pageAndSortData: {
      handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;

        this.$emit("updateSurveys", currentValue);
      },
      deep: true,
    },
  },
  components: {
    NoDataAlert,
    Page,
  },
  methods: {
    ...mapActions("screenerSurveys", [
      "associateScreenerSurvey",
      "deleteScreenerSurvey",
      "createScreenerSurveyFromSurvey",
      "setSurveyIndex"
    ]),
    ...mapActions("surveysNew", {
      getSurvey: "getSurvey",
      clearSurvey: "clearSurvey",
    }),
    async toggleAssociated(index) {
      const survey = this.surveys[index];

      if (survey.associated) {
        await this.associateScreenerSurvey({
          screenerId: this.screenerId,
          surveyId: survey.id,
        }).then((response) => {
          if (response !== false) {
            this.associationSnackbar = true;
            this.associationStatus = "associated";
            this.$emit("updateSurveys", this.pageAndSortData);
          } else {
            this.errorSnackbar = true;
          }
        });
      } else {
        const screenerSurvey = this.screenerSurveys.filter((qs) => {
          if (qs.globalId === survey.id) {
            return qs.relationships.screener.data.id;
          }
        });

        if (screenerSurvey.length > 0) {
          const response = await this.deleteScreenerSurvey(
            screenerSurvey[0].screenerSurveyId
          );
          if (response !== false) {
            this.associationSnackbar = true;
            this.associationStatus = "unassociated";
            this.$emit("updateSurveys", this.pageAndSortData);
          }
        }
      }
    },
    async loadSurveyData(survey) {
      //use global survey id if not associated
      let surveyId = survey.id;
      //if survey is associated then lets show pages,questions,options from cloned survey
      if (survey.clonedId !== undefined) {
        surveyId = survey.clonedId;
      }
      this.surveyPageCount = 0;
      await this.getSurvey(surveyId);
      this.createScreenerSurveyFromSurvey();
      // Set the survey index to the first key/index
      await this.setSurveyIndex(surveyId);
      this.survey = this.newScreenerSurveys[surveyId];
      this.surveyPageCount = Object.keys(this.survey.surveyData.pages).length;
      this.dialog = true;
    },
    async endSurveyData() {
      await this.clearSurvey();
      this.dialog = false;
    },
  },
};
</script>
