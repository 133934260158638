<template>
  <v-card-text>
    <v-card>
      <section-header :isCollapsable="false"> Global Surveys </section-header>

      <v-card-text>
        <screener-surveys-association-table
          :isLoading="surveysLoading"
          :screenerId="screenerId"
          :screenerSurveys="screenerSurveyList"
          :surveys="surveys"
          :surveysCount="surveysCount"
          @updateSurveys="handleSurveysUpdate($event)"
        >
        </screener-surveys-association-table>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ScreenerSurveysAssociationTable from "@admin/components/screener_surveys/ScreenerSurveysAssociationTable.vue";
import SectionHeader from "@admin/components/SectionHeader.vue";

export default {
  name: "SiteAssociation",
  components: {
    SectionHeader,
    ScreenerSurveysAssociationTable,
  },
  computed: {
    ...mapState("screeners", ["screener"]),
    ...mapState("screenerSurveys", [
      "screenerSurvey",
      "screenerSurveys",
      "screenerSurveyList",
    ]),
    ...mapState("surveysNew", ["surveys", "surveysCount"]),
  },
  data() {
    return {
      screenerId: this.$route.params.screenerId,
      surveysLoading: false,
    };
  },
  methods: {
    ...mapActions("screeners", ["getScreener"]),
    ...mapActions("screenerSurveys", ["getScreenerSurveys"]),
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setEngagementBreadcrumb",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    ...mapActions("surveysNew", [
      "getSurveys",
      "searchSurveys",
      "setAssociations",
    ]),
    handleSiteLocationsUpdate() {},
    async handleSurveysUpdate(options) {
      this.surveysLoading = true;
      await this.getScreenerSurveys({ screenerId: this.screenerId });
      this.surveysLoading = false;
    },
  },
  async mounted() {
    this.surveysLoading = true;
    let actionButtons = [];
    await Promise.all([
      this.getScreener({ id: this.screenerId }),
      this.getSurveys(),
      this.getScreenerSurveys({ screenerId: this.screenerId }),
    ]).then(async () => {
      await this.setAssociations(this.screenerSurveyList);
    });

    this.setTitleObject("Survey Association");

    this.setBreadcrumbs();

    actionButtons.push({
      attributes: {
        searchCallback: (searchQuery) => this.searchSurveys(searchQuery),
      },
      component: "SearchBar",
      index: 0,
    });
    actionButtons.push({
      component: "DoneButton",
      attributes: {
        to: {
          name: 'ScreenerSurveysEdit',
          params: { id: this.screenerId },
        },
      },
      index: 1,
    });
    this.setActionButtons(actionButtons);

    this.surveysLoading = false;
  },
};
</script>

<style scoped></style>
